// src/components/Home.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './maintenance.css';


const Maintenance = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/data');
        setData(response.data);
      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <img src="/images/logocon.png" alt="Logo" className="logo" />
      <h1>GLOBAL NETWORKING TELECOM S.A.S</h1>
      <h2>DOING MAINTENANCE</h2>
      <h3>No need to worry</h3>
      <p>Our website is down for planned maintenance, we should be back up momentarily. 
        Check back soon! </p>
         <p>Contact us:</p>
        <p href="http://mail.google.com/mail/u/0/#inbox?compose=new"> admin@gntsas.com</p>
        <p href="http://mail.google.com/mail/u/0/#inbox?compose=new"> operaciones@gntsas.com </p>

      {data && <p>{data.message}</p>}
      <footer>® 2024 MG GROUP SOLUTIONS All rights reserved.</footer>
    </div>
  );
};


export default Maintenance;


